<template>
  <section class="content">
    <table class="table table-hover" ref="tblshipper">
      <thead>
        <tr>
          <th>SHIPPER</th>
          <th>NAMA TOKO</th>
          <th>EMAIL</th>
          <th>NO. HP</th>
          <th>PIC</th>
          <th>ALAMAT</th>
          <th>DETAIL ALAMAT</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "GudangShipper",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      groups: [],
      formTitle: "Tambah List Origin",
      form: {
        kurir_id: [],
        kode: "",
        nama: "",
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
  },
  mounted() {
    const e = this.$refs;
    var self = this;
    this.table = createTable(e.tblshipper, {
      title: "",
      roles: ["read"],
      ajax: "/shipper/shipper_address",
      processing: true,
      scrollX: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      columns: [
        { data: "nama" },
        { data: "sender_name" },
        { data: "email" },
        { data: "pic_tel" },
        { data: "pic_name" },
        { data: "gudang",
        render: function (data, type, row, meta) {
            return row.propinsi + ", " +row.kota + ", " + row.kecamatan;
        }, },
        { data: "addr_detail" },
      ],
      filterBy: [0, 1, 2, 3, 4, 5, 6],
      rowCallback: function (row, data) {
      },
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
    });
  },
};
</script>